<template>
  <b-card :header="$t('position')" border-variant="secondary" header-border-variant="secondary">
    <b-card-text>   
      <div class="d-flex  align-items-center">
        <b-form-group class="mt-1 w-100 mr-2" :label="$t('title')" label-for="input-3">
          <b-form-select
            id="input-3"
            v-model="title"
            :options="options"
            required
            @change="ChangeOption('title')"
          >
          </b-form-select>
        </b-form-group>
        <b-form-checkbox v-model="header.title.hide" switch size="md"></b-form-checkbox>
      </div>
      <div class="d-flex  align-items-center">
        <b-form-group class="w-100 mr-2" label="Logo" label-for="input-3">
          <b-form-select
            id="input-3"
            v-model="logo"
            :options="options"
            required
            @change="ChangeOption('logo')"
          ></b-form-select>
        </b-form-group>
        <b-form-checkbox v-model="header.logo.hide" switch size="md"></b-form-checkbox>
      </div>
      <div class="d-flex  align-items-center">
        <b-form-group class="w-100 mr-2" :label="$t('usermenu')" label-for="input-3">
          <b-form-select
            id="input-3"
            v-model="auth"
            :options="options"
            required
            @change="ChangeOption('auth')"
          ></b-form-select>
        </b-form-group>
        <b-form-checkbox v-model="header.auth.hide" switch size="md"></b-form-checkbox>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BFormSelect, BFormGroup, BFormCheckbox
} from 'bootstrap-vue'
export default {
  components:{
    BCard,
    BCardText,
    BFormSelect,
    BFormGroup,
    BFormCheckbox
  },
  props:['header'],
  data() {
    return {
      options:[
        { text: this.$t('left'), value:1},
        { text: this.$t('center'), value:2},
        { text: this.$t('right'), value:3}
      ],
      logo: this.header.logo.position,
      title: this.header.title.position,
      auth: this.header.auth.position
    }
  },
  methods:
  {
    ChangeOption(option){
      if(option == "logo")
      {
        if(this.logo == this.header.title.position)
        {
          this.header.title.position = this.header.logo.position;
          this.title = this.header.title.position;
          this.header.logo.position = this.logo;
        }
        else if(this.logo == this.header.auth.position)
        {
          this.header.auth.position = this.header.logo.position;
          this.auth = this.header.auth.position;
          this.header.logo.position = this.logo;
        }
      }

      if(option == "title")
      {
        if(this.title == this.header.logo.position)
        {
          this.header.logo.position = this.header.title.position;
          this.logo = this.header.logo.position;
          this.header.title.position = this.title;
        }
        else if(this.title == this.header.auth.position)
        {
          this.header.auth.position = this.header.title.position;
          this.auth = this.header.auth.position;
          this.header.title.position = this.title;
        }
      }
      if(option == "auth")
      {
        if(this.auth == this.header.logo.position)
        {
          this.header.logo.position = this.header.auth.position;
          this.logo = this.header.logo.position;
          this.header.auth.position = this.auth;
        }
        else if(this.auth == this.header.title.position)
        {
          this.header.auth.position = this.header.auth.position;
          this.title = this.header.title.position;
          this.header.auth.position = this.auth;
        }
      }
    }
  }
}
</script>

<style>

</style>