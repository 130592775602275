import axios from '@axios'
const APP_URL = process.env.VUE_APP_URL

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchHeaders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/headers`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchHeader(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/headers/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addHeader(ctx, headerData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${APP_URL}/headers`,  headerData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
   
    updateHeader(ctx, { id, headerData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${APP_URL}/headers/${id}`, headerData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchHeaderIamge(ctx,  id ) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/files/${id}?path=headers`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeHeader(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${APP_URL}/headers/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
