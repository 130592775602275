<template>
  <div>
    <!-- Form -->
    <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
      <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
        <b-row>
          <b-col cols="4">
            <validation-provider #default="validationContext" name="name" rules="required">
              <b-form-group label="name" label-for="name">
                <template #label>{{ $t('labels.name') }}</template>
                <b-form-input id="name" type="text" v-model="header.name"
                  :state="getValidationState(validationContext)" />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="4">
            <validation-provider #default="validationContext" name="BackgroundColor" rules="required">
              <b-form-group label="Background color" label-for="backgroundcolor">
                <template #label>{{ $t('labels.backgroundcolor') }}</template>
                <b-form-input id="backgroundcolor" type="color" v-model="header.background_color"
                  :state="getValidationState(validationContext)" />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="4">
            <!-- background image -->
            <image-field-form :text="$t('labels.backgroundimage')" class="w-100" :value="header.background_image"
              path="headers" @changeImage="captureBackground" :images="listImages.value">
            </image-field-form>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="4">
            <b-card :header="$t('title')" border-variant="secondary" header-border-variant="secondary">
              <b-card-text>
                <div class="d-flex  align-items-center mt-1">
                  <!-- background image -->
                  <validation-provider #default="validationContext" name="text" class="w-100">
                    <b-form-group label="text" label-for="text">
                      <template #label>{{ $t('text') }}</template>
                      <b-form-input id="text" type="text" v-model="header.title.text"
                        :state="getValidationState(validationContext)" />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </div>
                <div class="d-flex  align-items-center">
                  <validation-provider #default="validationContext" name="textColor" class="w-100">
                    <b-form-group label="textColor" label-for="textColor">
                      <template #label>{{ $t('color') }}</template>
                      <b-form-input id="textColor" type="color" v-model="header.title.color"
                        :state="getValidationState(validationContext)" />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </div>
                <div class="d-flex  align-items-center">
                  <validation-provider #default="validationContext" name="fontSize" class="w-100">
                    <b-form-group label="With" label-for="fontSize">
                      <template #label>{{ $t('font_size') }}</template>
                      <b-form-input id="fontSize" type="text" v-model="header.title.font_size"
                        :state="getValidationState(validationContext)" />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </div>
              </b-card-text>
            </b-card>
          </b-col>
          <b-col cols="4">
            <b-card header="Logo" border-variant="secondary" header-border-variant="secondary">
              <b-card-text>
                <div class="d-flex  align-items-center mt-1">
                  <!-- background image -->
                  <image-field-form class="w-100" text="Logo" :value="header.logo.href" path="headers"
                    @changeImage="captureLogo" preview="true" :images="listImages.value">
                  </image-field-form>
                </div>
                <div class="d-flex  align-items-center">
                  <validation-provider #default="validationContext" name="LogoWith" class="w-100">
                    <b-form-group label="With" label-for="LogoWith">
                      <template #label>{{ $t('width') }}</template>
                      <b-form-input id="LogoWith" type="text" v-model="header.logo.with"
                        :state="getValidationState(validationContext)" />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </div>
                <div class="d-flex  align-items-center">
                  <validation-provider #default="validationContext" name="LogoHeight" class="w-100">
                    <b-form-group label="With" label-for="LogoHeight">
                      <template #label>{{ $t('height') }}</template>
                      <b-form-input id="LogoHeight" type="text" v-model="header.logo.height"
                        :state="getValidationState(validationContext)" />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </div>
              </b-card-text>
            </b-card>
          </b-col>
          <b-col cols="4">
            <b-card :header="$t('usermenu')" border-variant="secondary" header-border-variant="secondary">
              <b-card-text>
                <div class="d-flex  align-items-center mt-1">
                  <validation-provider #default="validationContext" name="ColorMenu" class="w-100">
                    <b-form-group label="ColorMenu" label-for="ColorMenu">
                      <template #label>{{ $t('color') }}</template>
                      <b-form-input id="ColorMenu" type="color" v-model="header.auth.color"
                        :state="getValidationState(validationContext)" />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </div>
                <div class="d-flex  align-items-center">
                  <validation-provider #default="validationContext" name="BackgroundMenu" class="w-100">
                    <b-form-group label="BackgroundMenu" label-for="BackgroundMenu">
                      <template #label>{{ $t('labels.backgroundcolor') }}</template>
                      <b-form-input id="BackgroundMenu" type="color" v-model="header.auth.background_color"
                        :state="getValidationState(validationContext)" />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </div>
                <div class="d-flex  align-items-center">
                  <validation-provider #default="validationContext" name="BackgroundMenu" class="w-100">
                    <b-form-group label="BackgroundMenu" label-for="BackgroundMenu">
                      <template #label>{{ $t('text_color') }}</template>
                      <b-form-input id="BackgroundMenu" type="color" v-model="header.auth.text_color"
                        :state="getValidationState(validationContext)" />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </div>
              </b-card-text>
            </b-card>
          </b-col>

        </b-row>
        <b-row align-v="center" class="mt-1">
          <b-col cols="8">
            <header-type :header="header"></header-type>
          </b-col>
          <b-col cols="4">
            <header-position :header="header"></header-position>
          </b-col>
        </b-row>
        <div class="text-center">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"
            v-if="!header._id">
            {{ $t('buttons.add') }}
          </b-button>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit" v-else>
            {{ $t('buttons.update') }}
          </b-button>
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
            :to="$router.resolve({ name: 'apps-headers-list' }).href">
            {{ $t('buttons.cancel') }}
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BFormCheckbox, BForm, BFormFile, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormSelect, BRow, BCol, BCard, BCardText
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from "@vue/composition-api";
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import HeaderPosition from './header-position'
import HeaderType from './header-type'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ImageFieldForm from '@/views/components/ImageFieldForm'
import { useToast } from 'vue-toastification/composition'



export default {
  components:
  {
    BFormCheckbox, BForm, BFormFile, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormSelect, BRow, BCol, BCard, BCardText,
    ValidationProvider,
    ValidationObserver,
    HeaderPosition,
    HeaderType,
    ImageFieldForm
  },
  props: ['header', 'images'],
  directives: {
    Ripple,
  },
  data() {
    const listImages = ref([]);
    this.getImages()
    return {
      required,
      listImages,
      options: [
        { text: this.$t('left'), value: 1 },
        { text: this.$t('center'), value: 2 },
        { text: this.$t('right'), value: 3 }
      ],
    }
  },

  setup(props) {
    const toast = useToast()
    const onSubmit = async () => {
      try {
        if (props.header._id) {
          await store.dispatch('app-header/updateHeader', { id: props.header._id, headerData: props.header })
          toast({
            component: ToastificationContent,
            props: {
              title: 'Header update',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
        else {
          await store.dispatch('app-header/addHeader', props.header)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Header created',
              icon: 'EditIcon',
              variant: 'success',
            },
          })

        }

      } catch (error) {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: error.msg || 'error creating or update header',
            icon: 'EditIcon',
            variant: 'error',
          },
        })
      }
    }

    const data = ref(JSON.parse(JSON.stringify(props.header)));
    const resetdata = () => {
      data.value = JSON.parse(JSON.stringify(props.header));
    };

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetdata)



    return {
      data,
      refFormObserver,
      getValidationState,
      resetForm,
      onSubmit
    };
  },
  methods: {
    getImages() {
      console.log(store.state.whitelabelCurrencyNabvar.whitelabel.client);
      setTimeout(
        () =>
          store.dispatch('app-header/fetchHeaderIamge', store.state.whitelabelCurrencyNabvar.whitelabel.client)
            .then(response => { this.listImages.value = response.data })
            .catch(error => {
              console.error(error)
            }).finally(()=> console.log(this.listImages.value)), 1000
      )
    },
    async captureLogo(value) {
      if (value == 'reload') {
        return this.getImages()
      } else if (value == 'error') {
        return toast({
          component: ToastificationContent,
          props: {
            title: 'error to load image',
            icon: 'EditIcon',
            variant: 'error',
          },
        })
      } else {
        console.log(value)
        this.header.logo.href = value || ''
      }
    },
    async captureBackground(value) {
      if (value == 'reload') {
        this.getImages()
      } else if (value == 'error') {
        toast({
          component: ToastificationContent,
          props: {
            title: 'error to load image',
            icon: 'EditIcon',
            variant: 'error',
          },
        })
      } else {
        this.header.background_image = value || ''
      }
    }
  }
}
</script>

<style>
</style>
