<template>
  <component :is="header === undefined ? 'b-card' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="header === undefined"
    >
      <h4 class="alert-heading">
        Error fetching header data
      </h4>
      <div class="alert-body">
        No header found with this header id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-headers-list'}"
        >
          Header List
        </b-link>
        for other headers.
      </div>
    </b-alert>

    <b-tabs
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{$t('headers')}}</span>
        </template>
        <header-form :header="header" :images="images"></header-form>
       
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard,  BAlert, BCol, BRow, BFormInput, BLink} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import headerStoreModule from '../headerStoreModule'
import HeaderForm from './header-form'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BCol,
    BRow,
    BFormInput,
    BLink,
    HeaderForm
  },
  data() {
    return {
      /* require, */
      header1:
      {
        name: null,
        background_color: null, //color de fondo header
        background_image: null, //imagen de fondo del header
        auth: { //Colores y  posicion del menu "register y login"
          background_color: null,
          color: null,
          text_color:null,
          position: 3,
          hide:false,
        },
        logo: { //logo de la pagina
          href: null,
          position: 2,
          with:"250",
          height:"165",
          hide:false,
        },
        title: { //breve texto o posible titulo de la pagina
          text:null,
          color: null,
          font_size:18,
          position: 1,
          hide:false,
        }

      }
    }
  },
  watch:
  {
    "$store.state.whitelabelCurrencyNabvar.whitelabel": function(){
      this.storeImage()
    }
  },
  beforeMount()
  {
    this.storeImage();
  },
  setup() {
    
    const header = ref(
      {
        name: null,
        background_color: null, //color de fondo header
        background_image: null, //imagen de fondo del header
        auth: { //Colores y  posicion del menu "register y login"
          background_color: null,
          color: null,
          text_color:null,
          position: 3,
          hide:false,
        },
        logo: { //logo de la pagina
          href: null,
          position: 2,
          with:"250",
          height:"165",
          hide:false,
        },
        title: { //breve texto o posible titulo de la pagina
          text:null,
          color: null,
          font_size:18,
          position: 1,
          hide:false,
        }

      }
    )

     const HEADER_APP_STORE_MODULE_NAME = 'app-header'
    if (!store.hasModule(HEADER_APP_STORE_MODULE_NAME)) store.registerModule(HEADER_APP_STORE_MODULE_NAME, headerStoreModule)
    onUnmounted(() => {
      if (store.hasModule(HEADER_APP_STORE_MODULE_NAME)) store.unregisterModule(HEADER_APP_STORE_MODULE_NAME)
    })
    if(router.currentRoute.params.id)
    {
       store.dispatch('app-header/fetchHeader', { id: router.currentRoute.params.id })
          .then(response => { header.value = response.data })
          .catch(error => {
            console.error(error)
            if (error.response.status === 404) {
              header.value = undefined
            }
          })
    }
    const images = ref([]);
     const storeImage = async () => {

        await store.dispatch('app-header/fetchHeaderIamge', store.state.whitelabelCurrencyNabvar.whitelabel.client)
        .then(response => { images.value = response.data })
        .catch(error => {
          console.error(error)
          if (error.response.status === 404) {
            images.value = []
          }
      })
    }

    return {
      header,
      images,
      storeImage
    }

  },
}
</script>

<style>


</style>
