<template>
  <b-card 
    border-variant="secondary"
    :header="$t('structure')"
    header-border-variant="secondary"
    align="center"
    >
    <b-card-text class="text-center align-items-center">
      <b-row align-v="center">
        <b-col :order="header.logo.position">
          <b-card class="text-center align-items-center"  v-if="!header.logo.hide">
            <b-card-text>
              <svg 
                class="bd-placeholder-img rounded-circle" 
                width="60"
                  height="60" 
                  xmlns="http://www.w3.org/2000/svg" 
                  role="img"
                  aria-label="Logo"
                  preserveAspectRatio="xMidYMid slice" 
                  focusable="false">
                  <title>Logo</title>
                  <rect width="100%" height="100%" fill="#6c757d"></rect>
                <text x="16%" y="50%" fill="#dee2e6" dy=".3em">LOGO</text></svg>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col :order="header.title.position">
        <b-card class="text-center align-items-center" v-if="!header.title.hide">
          <b-card-text class="text-center align-items-center">
            {{$t('titulo')}}
          </b-card-text>
        </b-card>
        </b-col>
        <b-col :order="header.auth.position">
          <b-card  v-if="!header.auth.hide">
            <b-card-text>
              <b-button 
                type="button" 
                role="button" 
                pill
                class="d-flex"
                size="sm"
                disabled
              >
              <span class="d-flex items-center align-items-center">
                <span>{{$t('buttons.usermenu')}}</span>
              </span>
              </b-button>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>
<script>
import {
   BCard, BCardGroup, BCol, BRow, BButton, BCardText, BCardTitle
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardGroup,
    BCol,
    BRow,
    BButton,
    BCardText,
    BCardTitle
  },
  props:['header']

}
</script>